export const BASE_API_URL = "https://exodus.kontrivetechnologies.com/v1.0.0";
//export const BASE_API_URL = "http://localhost:9095/v1.0.0";
export const REQUEST_METHODS = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	PATCH: "PATCH",
	DELETE: "DELETE",
};
export default {
	BASE_API_URL,
	REQUEST_METHODS,
};
export const ENC_KY =
	"hg5ghZxQzRngmHqLQLxTz1TqkVPUNJ7623Hp59iBI65uXFQcqPjkUhQYrHhHMqWYmROOEJaA2NOl01SFBbAy9F6u0zWK8Pai3ZcZjIY1p68IMLMgtVu3TECIMnJIGY7yELJnb4CVBWlU2Zev";
